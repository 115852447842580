import { getProductId } from '~/utils/product'

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return

  const licensedTerritory = useLicensedTerritory()
  const cachedProductId = getCachedProductId(
    licensedTerritory,
    getProductId(to) ?? '',
  )

  if (cachedProductId) return

  const { log } = useDatadog()

  log(DATADOG_LOG_TYPES.Info, {
    component: 'middleware/cachedProduct',
    message: 'No cached product was found. Redirecting to /play-now',
    messageContext: { ...to },
  })

  return navigateTo('/play-now')
})
